<template>
  <div class="pickFrame" id="pickFrame" @click="closePick">
    <div class="pickBox" :class="{ active: showPickFunc }">
      <p class="title">{{ pleaseSelect }}</p>
      <div class="pickFunc">
        <div class="pick" @click="enterStore(1)">
          <img src="@/assets/icon/delivery.png" alt="" />
          <div>
            <p>{{ haoWeiSuDa }}</p>
            <p></p>
          </div>
          <p class="botBg"></p>
        </div>
        <div class="pick" @click="enterStore(2)">
          <img src="@/assets/icon/pickup.png" alt="" />
          <div>
            <p>{{ WaiMaiZiQu }}</p>
            <p></p>
          </div>
          <p class="botBg"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import language from "../../assets/js/language.js";

export default {
  name: "PickOrder",
  data() {
    return {
      // 静态文字
      haoWeiSuDa: "",
      start: "",
      WaiMaiZiQu: "",
      pleaseSelect: "",
    };
  },
  props: {
    showPickFunc: { type: Boolean, default: false },
  },
  methods: {
    closePick(e) {
      if (e.target.id == "pickFrame") {
        this.$emit("pickOrder", false);
      }
    },
    enterStore(type) {
      this.$emit("selectPickFunc", type);
    },
  },
  created() {
    var languageType;
    if (this.$store.state.language == 1) {
      languageType = "chinese";
    } else {
      languageType = "english";
    }
    this.haoWeiSuDa = language.haoWeiSuDa[languageType];
    this.start = language.start[languageType];
    this.WaiMaiZiQu = language.WaiMaiZiQu[languageType];
    this.pleaseSelect = language.pleaseSelect[languageType];
  },
};
</script>
<style scoped>
.pickFrame {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.pickBox {
  width: 100%;
  padding: 0.16rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.08rem 0.08rem 0 0;
  position: fixed;
  bottom: -100vh;
  left: 0;
  transition: all 0.2s linear;
}
.pickBox.active {
  bottom: 0;
}
.title {
  height: 22px;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 22px;
  color: #03060d;
  text-align: left;
}
.pickFunc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.16rem;
}
.pick {
  width: 1.65rem;
  height: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 20px;
  color: #1e1f21;
  padding: 0.16rem 0.12rem;
  box-sizing: border-box;
  /* border: 1px solid #cacbcc; */
  border-radius: 0.1rem;
  text-align: left;
  box-shadow: 0px 2px 5px 1px #c2c4c9;
  position: relative;
  overflow: hidden;
}
.botBg{
  width: 100%;
  height: .08rem;
  background-color: rgb(208, 45, 53);
  position: absolute;
  bottom: 0;
  left: 0;
}
.pick img {
  width: 0.48rem;
  height: 0.48rem;
  object-fit: cover;
  margin-right: 0.08rem;
}
.pick div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pick div p:last-child {
  width: 0.14rem;
  height: 0.14rem;
  background: url("../../assets/icon/play.png") no-repeat center;
  background-size: cover;
  margin-left: 0.04rem;
}
</style>