<template>
  <div class="coupon">
    <topbar :left="false" :title="title"></topbar>
    <div class="topbarPageBox">
      <div class="tabBar">
        <p @click="selectTab(1)" :class="{ active: type == 1 }">
          <span>
            {{ lanType == 1 ? "全部" : "All" }}
          </span>
          <span></span>
        </p>
        <p @click="selectTab(2)" :class="{ active: type == 2 }">
          {{ lanType == 1 ? "餐廳點餐" : "Restaurant Ordering" }}
          <span></span>
        </p>
        <p @click="selectTab(3)" :class="{ active: type == 3 }">
          {{ lanType == 1 ? "好味速遞" : "Online Ordering" }}
          <span></span>
        </p>
      </div>
      <!-- <p class="couponNum">
        <span
          @click="secectCouponNumType(1)"
          :class="{ active: couponNumActive == 1 }"
          >{{ lanType == 1 ? "現可使用" : "For now" }}
          {{ nowCoupon.length }}</span
        >
        <span
          @click="secectCouponNumType(2, 'H01')"
          :class="{ active: couponNumActive == 2 }"
          >{{ lanType == 1 ? "早餐" : "Breakfas" }}
          {{ breakfirst.length }}</span
        >
        <span
          @click="secectCouponNumType(3, 'H03')"
          :class="{ active: couponNumActive == 3 }"
          >{{ lanType == 1 ? "下午茶" : "Tea" }} {{ tea.length }}</span
        >
        <span
          @click="secectCouponNumType(4)"
          :class="{ active: couponNumActive == 4 }"
          >{{ lanType == 1 ? "不適用" : "Not Applicable" }}
          {{ unNormal.length }}</span
        >
      </p> -->
    </div>
    <mescroll-vue ref="mescroll" :down="mescrollDown" @init="mescrollInit">
      <div class="list" v-if="showCouponList.length">
        <coupon-list
          :type="type"
          v-for="(item, index) in showCouponList"
          :key="index"
          :couponInfo="item"
          :showBtn="true"
          @selectCoupon="selectCoupon"
          @showPick="showPick"
          @couponDataLayPush="couponDataLayPush"
          :ref="'couponItem' + index"
        ></coupon-list>
      </div>

      <div class="list active" v-else>
        <none-data
          :title="lanType == 1 ? '暫時未有優惠券' : 'No available coupons'"
          :btnText="lanType == 1 ? '去點餐' : 'Order Now'"
          :type="1"
        ></none-data>
      </div>
    </mescroll-vue>

    <div class="pickOrder">
      <pick-order
        v-show="showPickFunc"
        @pickOrder="showPick"
        :showPickFunc="showPickFunc"
        @selectPickFunc="selectPickFunc"
      ></pick-order>
    </div>
  </div>
</template>
<script>
import Topbar from "../components/Topbar.vue";
import CouponList from "../components/coupon/CouponList.vue";
import NoneData from "../components/common/NoneData.vue";
import PickOrder from "../components/pickOrder/PickOrder.vue";
import MescrollVue from "mescroll.js/mescroll.vue";
import { postPaas } from "../assets/utils/request";
import setHashTag from "../assets/utils/setHashTag";

export default {
  name: "Coupon",
  data() {
    return {
      title: this.$store.state.language == 1 ? "優惠券" : "Coupons",
      type: 1,
      couponNumActive: 0,
      showList: true,
      couponList: [], //优惠券列表
      couponListCopy: [], //选择的列表
      showCouponList: [],
      nowCoupon: [], //当前可用
      breakfirst: [], //早餐
      dinner: [], //晚餐
      tea: [], //下午茶
      lunch: [], //午餐
      unNormal: [], //不可用
      shopCoupon: [], //到店专用
      sendCoupon: [], //外送专用
      couponInfo: null,
      canClick: true,
      showPickFunc: false,
      lanType: this.$store.state.language,
      mescroll: null,
      mescrollDown: {
        //下拉刷新
        callback: this.downCallback,
        textLoading: "",
        textOutOffset: "",
        textInOffset:
          this.$store.state.language == 1
            ? "往下滑更新版面"
            : "Swipe down to refresh",
      },
      mescrollUp: {
        //上啦加载
        // callback: this.upCallback,
      },
    };
  },
  methods: {
    // 埋點
    couponDataLayPush(info) {
      var event = "all_coupon";
      if (this.type == 2) {
        event = "dine_in_coupon";
      } else if (this.type == 3) {
        event = "delivery_coupon";
      }
      if (info) {
        var ecommerce = {
          Coupon: info.voucherName,
          coupon_ID: info.voucherId,
          expiary_date: info.date,
          coupon_variant: info.voucherDesc,
        };
        if (info.voucherCategory) {
          for (var i = 0; i < info.voucherCategory.length; i++) {
            ecommerce["Coupon_category_" + (i + 1)] = info.voucherCategory[i];
          }
        }
      }
      window.dataLayer.push({
        event,
        ecommerce: ecommerce || {},
      });
    },
    downCallback() {
      this.showCouponList = this.couponListCopy = this.couponList = [];
      this.couponListCopy = [];
      this.sendCoupon = [];
      this.shopCoupon = [];
      this.getCoupon();
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    selectCoupon(info) {
      this.couponInfo = info;
    },
    showPick() {
      this.showPickFunc = !this.showPickFunc;
      if (this.showPickFunc) {
        this.$store.commit("updateTabbarShow", false);
      } else {
        this.$store.commit("updateTabbarShow", true);
      }
    },
    selectPickFunc(type) {
      var coupon = JSON.stringify(this.couponInfo);
      coupon = encodeURIComponent(coupon);
      if (type == 1) {
        if (this.$store.state.placeInfo) {
          var param = {
            businessType: "1",
            coupon: this.couponInfo,
          };
          param = JSON.stringify(param);
          param = encodeURIComponent(param);
          this.$router.push("/orderFood/" + param);
        } else {
          this.$router.push({
            path: "/selectShop",
            query: {
              type: 1,
              coupon: coupon,
            },
          });
        }
      } else {
        this.$router.push({
          path: "/selectShop",
          query: {
            type: 3,
            coupon: coupon,
          },
        });
      }
    },
    // 一级tab栏
    selectTab(type) {
      if (!this.canClick) return;
      this.canClick = false;
      this.type = type;
      this.couponListCopy = [];
      this.showCouponList = [];
      if (type == 1) {
        this.showCouponList = this.couponListCopy = this.couponList;
      } else if (type == 2) {
        this.showCouponList = this.couponListCopy = this.shopCoupon;
      } else if (type == 3) {
        this.showCouponList = this.couponListCopy = this.sendCoupon;
      }
      this.$nextTick(() => {
        this.showCouponList.forEach((val, index) => {
          this.$refs["couponItem" + index].setQRcode();
        });
      });
      this.couponDataLayPush();
      // this.couponNumActive = 0;
      // var map = ["H01", "H02", "H03"];
      // for (var c = 0; c < map.length; c++) {
      //   this.dressCoupon(map[c]);
      // }
      // 筛选不可用的优惠券
      // this.unNormal = [];
      // for (var a = 0; a < this.couponListCopy.length; a++) {
      //   if (this.couponListCopy[a].voucherStatus != 1) {
      //     this.unNormal.push(this.couponListCopy[a]);
      //   }
      // }
      this.canClick = true;
    },
    secectCouponNumType(type, couponType) {
      this.showCouponList = [];
      this.couponNumActive = type;
      if (type == 1) {
        couponType = this.$store.state.time;
      }
      this.dressCoupon(couponType, true, type);
    },
    // s筛选优惠券
    dressCoupon(couponType, can, type) {
      // 筛选早午餐
      var arr = [];
      if (type == 4) {
        for (var a = 0; a < this.couponListCopy.length; a++) {
          if (this.couponListCopy[a].voucherStatus != 1) {
            arr.push(this.couponListCopy[a]);
          }
        }
      } else {
        for (var i = 0; i < this.couponListCopy.length; i++) {
          if (
            this.couponListCopy[i].voucherType == 3 &&
            this.couponListCopy[i].voucherProductCode
          ) {
            if (this.couponListCopy[i].voucherStatus == 1) {
              if (
                this.couponListCopy[i].voucherHashtagCode &&
                this.couponListCopy[i].voucherHashtagCode.length
              ) {
                if (
                  this.couponListCopy[i].voucherHashtagCode.indexOf(
                    couponType
                  ) > -1
                ) {
                  arr.push(this.couponListCopy[i]);
                }
              } else {
                arr.push(this.couponListCopy[i]);
              }
            }
          } else if (
            this.couponListCopy[i].voucherType == 1 &&
            this.couponListCopy[i].voucherAmount
          ) {
            if (this.couponListCopy[i].voucherStatus == 1) {
              if (
                this.couponListCopy[i].voucherHashtagCode &&
                this.couponListCopy[i].voucherHashtagCode.length
              ) {
                if (
                  this.couponListCopy[i].voucherHashtagCode.indexOf(
                    couponType
                  ) > -1
                ) {
                  arr.push(this.couponListCopy[i]);
                }
              } else {
                arr.push(this.couponListCopy[i]);
              }
            }
          } else if (
            this.couponListCopy[i].voucherType == 2 &&
            this.couponListCopy[i].voucherDiscount
          ) {
            if (this.couponListCopy[i].voucherStatus == 1) {
              if (
                this.couponListCopy[i].voucherHashtagCode &&
                this.couponListCopy[i].voucherHashtagCode.length
              ) {
                if (
                  this.couponListCopy[i].voucherHashtagCode.indexOf(
                    couponType
                  ) > -1
                ) {
                  arr.push(this.couponListCopy[i]);
                }
              } else {
                arr.push(this.couponListCopy[i]);
              }
            }
          }
        }
        // if (couponType == "H01") {
        //   this.breakfirst = arr;
        // } else if (couponType == "H02") {
        //   this.lunch = arr;
        // } else if (couponType == "H03") {
        //   this.tea = arr;
        // } else if (couponType == "H04") {
        //   this.dinner = arr;
        // }
      }
      // if (this.$store.state.time == "H01") {
      //   this.nowCoupon = this.breakfirst;
      // } else if (this.$store.state.time == "H02") {
      //   this.nowCoupon = this.lunch;
      // } else if (this.$store.state.time == "H03") {
      //   this.nowCoupon = this.tea;
      // } else if (this.$store.state.time == "H04") {
      //   this.nowCoupon = this.dinner;
      // }
      if (can) {
        this.showCouponList = arr;
      }
    },
    // 获取优惠券
    async getCoupon() {
      var params = {
        actionName: "candao.member.voucherGuestGet",
        content: {
          voucherStatus: 1,
        },
      };
      if (this.$store.state.userInfo) {
        params.actionName = "candao.member.voucherGet";
      }
      if (this.$store.state.language == 1) {
        params.content.language = "TC";
      }
      let couponRes = await postPaas("UserUnify", params);
      if (couponRes.status == 1) {
        for (var k = 0; k < couponRes.data.data.length; k++) {
          var begin = couponRes.data.data[k].validBeginDate
            ? couponRes.data.data[k].validBeginDate
                .split("T")[0]
                .split("-")
                .reverse()
            : null;
          var end = couponRes.data.data[k].validEndDate
            ? couponRes.data.data[k].validEndDate
                .split("T")[0]
                .split("-")
                .reverse()
            : null;
          if (begin) {
            couponRes.data.data[k].date =
              begin.join("-") + "至" + end.join("-");
          } else if (end) {
            couponRes.data.data[k].date = end.join("-");
          } else {
            couponRes.data.data[k].date = "-";
          }
          // if (
          //   couponRes.data.data[k].voucherType == 3 &&
          //   couponRes.data.data[k].voucherProductCode
          // ) {
          //   this.couponList.push(couponRes.data.data[k]);
          // }
        }

        // 早晚餐
        // var map = ["H01", "H02", "H03", "H04"];
        // for (var i = 0; i < map.length; i++) {
        //   this.dressCoupon(map[i]);
        // }
        this.unNormal = [];
        for (var a = 0; a < couponRes.data.data.length; a++) {
          if (couponRes.data.data[a].voucherStatus == 1) {
            var nowTime = new Date().getTime();
            var startTime = 0;
            if (couponRes.data.data[a].validBeginDate) {
              startTime = new Date().getTime();
            }
            // var endTime = 0;
            var endTime = new Date("2200/12/12").getTime();
            if (couponRes.data.data[a].validEndDate) {
              endTime = new Date(couponRes.data.data[a].validEndDate).getTime();
            }
            if (nowTime >= startTime && nowTime <= endTime) {
              if (
                couponRes.data.data[a].voucherType == 3 ||
                couponRes.data.data[a].voucherType == 5
              ) {
                if (
                  couponRes.data.data[a].voucherProductCode &&
                  couponRes.data.data[a].voucherProductCode.length > 0
                ) {
                  this.couponList.push(couponRes.data.data[a]);
                  // 筛选不可用的优惠券
                  // if (couponRes.data.data[a].voucherStatus != 1) {
                  //   this.unNormal.push(couponRes.data.data[a]);
                  // }
                  if (couponRes.data.data[a].voucherCategoryCode) {
                    // 到店专用
                    if (
                      couponRes.data.data[a].voucherCategoryCode.indexOf(
                        "A03"
                      ) > -1 ||
                      couponRes.data.data[a].voucherCategoryCode.indexOf(
                        "A05"
                      ) > -1
                    ) {
                      this.shopCoupon.push(couponRes.data.data[a]);
                    }
                    // 外送专用
                    if (
                      couponRes.data.data[a].voucherCategoryCode.indexOf(
                        "A01"
                      ) > -1 ||
                      couponRes.data.data[a].voucherCategoryCode.indexOf(
                        "A02"
                      ) > -1
                    ) {
                      this.sendCoupon.push(couponRes.data.data[a]);
                    }
                  } else {
                    this.sendCoupon.push(couponRes.data.data[a]);
                    this.shopCoupon.push(couponRes.data.data[a]);
                  }
                }
              } else if (
                couponRes.data.data[a].voucherType == 1 ||
                couponRes.data.data[a].voucherType == 2 ||
                couponRes.data.data[a].voucherType == 4
              ) {
                if (
                  couponRes.data.data[a].voucherType == 1 ||
                  couponRes.data.data[a].voucherType == 4
                ) {
                  if (!couponRes.data.data[a].voucherAmount) {
                    continue;
                  }
                }
                if (
                  couponRes.data.data[a].voucherType == 2 &&
                  !couponRes.data.data[a].voucherDiscount
                ) {
                  continue;
                }
                if (
                  couponRes.data.data[a].voucherCategoryCode &&
                  couponRes.data.data[a].voucherCategoryCode.length > 0
                ) {
                  if (
                    couponRes.data.data[a].voucherCategoryCode.indexOf("A03") >
                      -1 ||
                    couponRes.data.data[a].voucherCategoryCode.indexOf("A05") >
                      -1
                  ) {
                    this.shopCoupon.push(couponRes.data.data[a]);
                  }
                  // 外送专用
                  if (
                    couponRes.data.data[a].voucherCategoryCode.indexOf("A01") >
                      -1 ||
                    couponRes.data.data[a].voucherCategoryCode.indexOf("A02") >
                      -1
                  ) {
                    this.sendCoupon.push(couponRes.data.data[a]);
                  }
                } else {
                  this.sendCoupon.push(couponRes.data.data[a]);
                  this.shopCoupon.push(couponRes.data.data[a]);
                }
                this.couponList.push(couponRes.data.data[a]);
              }
            }
          }
        }
        this.couponList.forEach((val) => {
          val.couTag = [];
          if (val.voucherCategoryCode && val.voucherCategoryCode.length > 0) {
            if (
              (val.voucherCategoryCode.indexOf("A01") > -1 ||
                val.voucherCategoryCode.indexOf("A02") > -1) &&
              (val.voucherCategoryCode.indexOf("A03") > -1 ||
                val.voucherCategoryCode.indexOf("A05") > -1)
            ) {
              val.sendFunc = 0;
            } else if (
              val.voucherCategoryCode.indexOf("A01") > -1 ||
              val.voucherCategoryCode.indexOf("A02") > -1
            ) {
              val.sendFunc = 1; //外送
              if (val.voucherCategoryCode.indexOf("A01") > -1) {
                val.couTag.push(1);
              }
              if (val.voucherCategoryCode.indexOf("A02") > -1) {
                val.couTag.push(2);
              }
            } else if (
              val.voucherCategoryCode.indexOf("A03") > -1 ||
              val.voucherCategoryCode.indexOf("A05") > -1
            ) {
              val.sendFunc = 2; //堂食
              if (val.voucherCategoryCode.indexOf("A03") > -1) {
                val.couTag.push(3);
              }
              if (val.voucherCategoryCode.indexOf("A05") > -1) {
                val.couTag.push(5);
              }
            }
          } else {
            val.sendFunc = 0; //通用
          }
        });
        this.couponListCopy = this.couponList;
        this.selectTab(this.type);
        // this.secectCouponNumType(1, true);
      }
      this.$nextTick(() => {
        this.mescroll.endSuccess();
      });
    },
  },
  created() {
    setHashTag();
    this.$store.commit("saveShowCoupon", false);

    // this.getCoupon();
  },
  components: {
    CouponList,
    NoneData,
    PickOrder,
    Topbar,
    MescrollVue,
  },
};
</script>
<style scoped>
.coupon {
  padding-bottom: 0.44rem;
}
.topbarPageBox {
  width: 100%;
  position: fixed;
  top: 0.43rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
}
.tabBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 22px;
  color: #808080;
  height: 56px;
}
.tabBar i {
  width: 1px;
  height: 16px;
  background-color: #808080;
}
.tabBar b {
  font-size: 0.12rem;
}
.tabBar p {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}
.tabBar p span:last-child {
  width: 40%;
  height: 2px;
  position: absolute;
  bottom: 5px;
}
.tabBar p.active span:last-child {
  background-color: #e4022b;
}
.tabBar p.active {
  color: #e4022b;
  font-weight: 500;
}
.couponNum {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: PingFang HK;
  font-weight: 400;
  color: #808080;
  padding: 0.08rem 0.16rem;
  box-sizing: border-box;
}
.couponNum span {
  white-space: nowrap;
}
.couponNum span + span {
  margin-left: 0.24rem;
}
.couponNum span.active {
  color: #e4022b;
}
.list {
  width: 100%;
  background-color: #f2f3f5;
  padding: 0.16rem;
  box-sizing: border-box;
  /* height: 90vh; */
  /* padding-top: 0.7rem; */
  padding-bottom: 0.7rem;
}
.list.active {
  height: calc(100vh - 1.46rem);
}
.list::-webkit-scrollbar {
  display: none;
}
.coupon::-webkit-scrollbar {
  display: none;
}
.mescroll {
  position: fixed;
  top: 1rem;
  bottom: 0;
  height: auto;
  padding: 0 !important;
  margin: 0;
}
</style>